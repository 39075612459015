import(/* webpackMode: "eager", webpackExports: ["default"] */ "D:\\projects\\vidyalaya_lms_website\\Components\\Common\\Bannerswiper.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "D:\\projects\\vidyalaya_lms_website\\Components\\Homepage\\FrequentQuestions.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "D:\\projects\\vidyalaya_lms_website\\Components\\InformationAbout.js");
;
import(/* webpackMode: "eager" */ "D:\\projects\\vidyalaya_lms_website\\node_modules\\next\\dist\\client\\image-component.js");
;
import(/* webpackMode: "eager" */ "D:\\projects\\vidyalaya_lms_website\\node_modules\\next\\dist\\client\\link.js");
;
import(/* webpackMode: "eager" */ "D:\\projects\\vidyalaya_lms_website\\styles\\Homepage\\Features.module.scss");
;
import(/* webpackMode: "eager" */ "D:\\projects\\vidyalaya_lms_website\\Ui_Elements\\CommonCard\\CommonCard.module.scss");
;
import(/* webpackMode: "eager" */ "D:\\projects\\vidyalaya_lms_website\\Ui_Elements\\OrangeSpan\\OrangeSpan.module.scss");
;
import(/* webpackMode: "eager" */ "D:\\projects\\vidyalaya_lms_website\\styles\\Homepage\\HomeBanner.module.scss");
;
import(/* webpackMode: "eager" */ "D:\\projects\\vidyalaya_lms_website\\styles\\Homepage\\Integrations.module.scss");
;
import(/* webpackMode: "eager" */ "D:\\projects\\vidyalaya_lms_website\\styles\\Homepage\\WhatsOurClient.module.scss");
;
import(/* webpackMode: "eager" */ "D:\\projects\\vidyalaya_lms_website\\styles\\Homepage\\Getstartednow.module.scss");
;
import(/* webpackMode: "eager" */ "D:\\projects\\vidyalaya_lms_website\\styles\\Homepage\\HowVidyalaya.module.scss");
;
import(/* webpackMode: "eager" */ "D:\\projects\\vidyalaya_lms_website\\styles\\Homepage\\WhyUs.module.scss");
;
import(/* webpackMode: "eager" */ "D:\\projects\\vidyalaya_lms_website\\Ui_Elements\\OrangeCard\\OrangeCard.module.scss");
